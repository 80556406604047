<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="Tipi lavorazione magazzino" />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-text-field
            light
            v-model="search"
            prepend-icon="search"
            @click:prepend="getTipiLavorazione"
            append-icon="cancel"
            @click:append="resetItems"
            placeholder="Cerca" />
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!tipi.length">
          Nessun tipo lavorazione trovato
        </v-alert>
        <v-list class="mt-2" v-if="tipi.length">
          <template v-for="(tipo, index) in tipi">
            <v-list-tile @click.stop="openTipoDialog(tipo)" :key="'tile-'+tipo._id">
              <v-list-tile-content>
                <v-list-tile-title class="text-capitalize" v-text="tipo.descrizione"></v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-icon @click.stop="deleteLavorazione(tipo)" color="error">delete</v-icon>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="tipo._id" v-if="index < tipi.length - 1" />
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-dialog
        v-model="showTipo"
        transition="dialog-bottom-transition"
        :overlay="false"
        width="500">
        <v-card>
          <v-toolbar class="grey lighten-3" card>
            <v-toolbar-title>
              <span v-if="tipo._id">{{tipo.nome}}</span>
              <span v-if="!tipo._id">Nuovo tipo lavorazione</span>
              {{ tipo.descrizione }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon @click.stop="showTipo=false"><v-icon>close</v-icon></v-btn>
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-card>
              <v-form ref="formTipo" v-model="validFormTipo">
                <v-container fluid grid-list-xl>
                  <v-layout row wrap>
                    <v-flex>
                      <v-text-field
                        label="Descrizione*"
                        v-model="tipo.descrizione"
                        :rules="[$rules.required, $rules.maxlength(200)]" />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-btn
                        class="ml-0"
                        color="primary"
                        :disabled="!validFormTipo"
                        @click.native="save()">
                        Salva
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-tooltip left>
        <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click.stop="openTipoDialog()">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo tipo lavorazione</span>
      </v-tooltip>
    </v-layout>
    <modal-confirm ref="confirm" />
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import modalConfirm from '@/components/common/ModalConfirm'
import _clone from 'lodash/clone'

export default {
  data: () => ({
    tipi: [],
    tipo: {},
    search: null,
    pages: 0,
    page: 1,
    paginate: 1000,
    validFormTipo: false,
    showTipo: false
  }),
  components: {
    PageTitle,
    'modal-confirm': modalConfirm
  },
  methods: {
    getTipiLavorazione () {
      this.$plsqlReadAll('tipo_lavorazione', {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      })
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.tipi = response.data.items
            this.pages = response.data.pages
          }
        })
        .catch(err => {
          console.log('errore', err)
          this.$showMessage({ type: 'error', text: 'Errore' })
        })
    },
    deleteLavorazione (tipo) {
      this.tipo = tipo
      this.$refs.confirm.show({
        modalTitle: 'Sicuro?',
        modalText: 'Stai per cancellare il tipo lavorazione <strong>' + tipo.descrizione + '</strong>.<br/>Confermi?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: this.remove,
        modalObj: tipo
      })
    },
    remove () {
      this.$plsqlDelete('tipo_lavorazione', this.tipo._id)
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Tipo lavorazione eliminato'
          })
          this.$refs.confirm.hide()
          this.getTipiLavorazione()
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare il tipo perché usato' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getTipiLavorazione()
    },
    save () {
      if (this.$refs.formTipo.validate()) {
        this.$plsqlSave('tipo_lavorazione', this.tipo)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Tipo lavorazione salvato'
            })
            this.showTipo = false
            this.getTipiLavorazione()
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit({ type: 'error', text: 'Errore salvataggio tipo lavorazione' })
          })
      }
    },
    openTipoDialog (tipo) {
      this.tipo = tipo ? _clone(tipo) : { }
      this.showTipo = true
      if (!tipo) this.$refs.formTipo.reset()
    }
  },
  mounted () {
    this.getTipiLavorazione()
  }
}
</script>
